.App img {
  height: 24px;

}


body {
  height: 100vh
}

.App {
  background-color: rgba(49, 77, 112, 1);
  font-family: 'Dongle', sans-serif;
  color: white;
  font-size: 30px;
  line-height: normal;
  height: 100vh;
}
