.grass rect {
  fill: rgba(88, 198, 43, 1);
  stroke: rgba(33, 123, 67, 1);
  stroke-width: 2;
}

.desert rect {
  fill: rgb(198, 109, 39);
  stroke: rgb(67, 35, 37);
  stroke-width: 2;
}

.desert line {
  stroke: rgb(239, 171, 102);
  stroke-width: 2;
}

.fog {
  stroke: rgba(128, 128, 128, 1);
  stroke-width: 2;
}

.grass line {
  stroke: rgba(186, 238, 92, 1);
  stroke-width: 2;
}

.city rect, .poi rect {
  fill: rgb(243, 242, 212);
  stroke: rgb(152, 153, 117);
  stroke-width: 2;
}

.city line, .poi line {
  stroke: rgb(254, 252, 238);
  stroke-width: 2;
}

.CityName {
  fill: #3e3e3e;
}

.CityName .back{
  fill: rgba(254, 252, 255, 0.5);
}
