.arrow .main {
  fill: rgb(133, 72, 245, 0.8);
  stroke: rgba(133, 72, 245, 1);
  stroke-width: 6;
  transition: fill 500ms, stroke 500ms
}

.arrows {
  opacity: 1;
  transition: opacity 0.5s linear;
}

.arrows.fetching {
  opacity: 0;
  transition: opacity 0.5s linear;
}


.mask .main {
  opacity: 0.1;
  fill: black;
  stroke-width: 0;
}

.mask .background {
  opacity: 0.1;
  fill: white;
  stroke-width: 0;
}

.arrow .main:hover {
  fill: rgba(72, 112, 245, 0.8);
  stroke: rgba(72, 112, 245, 1);
}

.arrow .light {
  stroke: rgba(203, 176, 253, 0.8);
  stroke-width: 4;
}

.shadow .main {
  fill: rgba(99, 99, 99, 0.5);
  stroke-width: 0;
}
