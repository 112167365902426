.Welcome {
  padding: 10px;
}

.Welcome .form {
  background-color: rgb(231, 235, 240);
  padding: 10px;
  border-radius: 5px;
}

.Welcome .input {
  padding-left: 10px;
  padding-right: 10px;
}

.Welcome .poi {
  margin-right: 5px;
  margin-bottom: 10px;
}

.Welcome .poi:hover {
  margin-right: 5px;
  margin-bottom: 10px;
  background: rgba(49, 77, 112, 1);
  color: white;
}

